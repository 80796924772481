require('./bootstrap');

import Uppy from '@uppy/core'

import DragDrop from '@uppy/drag-drop'
import ProgressBar from '@uppy/progress-bar'
import Tus from '@uppy/tus'
import ThumbnailGenerator from '@uppy/thumbnail-generator'

import swal from 'sweetalert2';
window.Swal = swal;



// const showPasswordBtn = $('#showPassword');
// showPasswordBtn.addEventListener('click', () => {
//    if (x.type === "password") {
//         x.type = "text";
//     } else {
//         x.type = "password";
//     }
// })

// function showPassword(){
//     var x = document.getElementById("showPassword");
//     if (x.type === "password") {
//         x.type = "text";
//     } else {
//         x.type = "password";
//     }
// }

// Function for displaying uploaded files
const onUploadSuccess = (elForUploadedFiles) => (file, response) => {
  const url = response.uploadURL
  const fileName = file.name

  const li = document.createElement('li')
  const a = document.createElement('a')
  a.href = url
  a.target = '_blank'
  a.appendChild(document.createTextNode(fileName))
  li.appendChild(a)

  document.querySelector(elForUploadedFiles).appendChild(li)
}

const uppyOne = new Uppy({ debug: true, autoProceed: true })
uppyOne
  .use(DragDrop, { target: '.example-one .for-DragDrop' })
  .use(Tus, { endpoint: 'https://tusd.tusdemo.net/files/' })
  .use(ProgressBar, { target: '.example-one .for-ProgressBar', hideAfterFinish: false })
  .use(ThumbnailGenerator, {thumbnailWidth: 200, waitForThumbnailsBeforeUpload: false })
  .on('upload-success', onUploadSuccess('.example-one .uploaded-files ol'))
  .on('thumbnail:generated', (file, preview) => {
    const img = document.createElement('img')
    img.src = preview
    img.width = 100
    document.getElementById('imgpreview').appendChild(img)
    })

const uppyTwo = new Uppy({ debug: true, autoProceed: false })
uppyTwo
  .use(DragDrop, { target: '.example-two .for-DragDrop' })
  .use(Tus, { endpoint: 'https://tusd.tusdemo.net/files/' })
  .use(ProgressBar, { target: '.example-two .for-ProgressBar', hideAfterFinish: false })
  .on('upload-success', onUploadSuccess('.example-two .uploaded-files ol'))

const uploadBtn = document.querySelector('.example-two button.upload-button')
uploadBtn.addEventListener('click', () => {
  uppyTwo.upload()
})
